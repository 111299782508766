.btn{
    padding: 1rem 1rem;
    border-radius: 0.2rem;
    color: #fff;
    border: none;
    text-transform: uppercase;
    font-size: 0.75rem;
    letter-spacing: 1.5px;
    font-weight: 500;
    cursor: pointer;
}

.btn--blue{
    background-color: var(--brand-blue);
}

.btn-purple{
    background-color: var(--base-purple);
    border-radius: 0.5rem;
    font-weight: 700;
}

.btn--large{
    padding: 1.4rem 2.8rem !important;
    font-size: 0.9rem;
    font-weight: 700;
}

.btn-iconwithtext{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    font-weight: 500;
    color: #fff;
    font-size: 0.9rem;
    border: none;
    cursor: pointer;
    position: relative !important;
    height: 70px;
}

.btn-iconwithtext:hover{
    color: var(--brand-yellow)
}

.btnicon{
    border: none;
    background-color: transparent;
    color: #fff;
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    cursor: pointer;
}