.input {
  width: 100%;
  padding: 1.2rem;
  border-radius: 0.5rem;
  background-color: #f1f2f6;
  border: none;
}

.input::placeholder {
  font-size: 1rem;
  color: rgb(0, 0, 0);
}

.input-select {
  font-size: 1rem;
  color: rgb(0, 0, 0);
}

@media screen and (max-width: 500px) {
  .input::placeholder {
    font-size: 0.65rem;
    color: rgb(0, 0, 0);
  }
}
